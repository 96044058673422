import React, { useEffect, useState } from 'react'
import { Link, useParams } from "react-router-dom";

import blogimg15 from '../assets/img/Team Picture1.jpg'


import ExamplesNavbar from './Navbars/ExamplesNavbar';
import DarkFooter from './Footers/DarkFooter';

const Article12 = () => {
  

  return (
    <div id="layout" className="pure-g">
       <ExamplesNavbar />
      <div className="content pure-u-1 pure-u-md-3-4">
        <div>
          <div className="posts">
            

          <section className="post" style={{display:'grid'}}>
                <div className='singleblog'>
                <header className="post-header">
                  <h3 style={{color:'#3a1772'}} className="post-title pt-3">
                  Essex Robotics Team Won the Best Demonstration Award for Agricultural Automation.
                
                  </h3>
                  <div class="postcard__subtitle">
					<time >
						<i class="fas fa-calendar-alt mr-2"></i>
           20/03/2025

					</time>
				</div>
        <br/>
                 
                </header>
                <div className="post-description">
                <img style={{float:'left',margin:' 0px  30px 20px 0px'}} src={blogimg15} title=""  width="420" height="300" />
           

  <p>Our project partner <a href='https://www.essex.ac.uk/'>University of Essex </a>Agrifood Robotics team, and spin-out <a href='https://versatilerobotx.com/'>Versatile RobotX</a>, established by Professor <a href='https://www.linkedin.com/in/klausmcdonaldmaier/?originalSubdomain=uk'>Klaus McDonald-Maier</a> and 
  <a href='https://www.vishwanathanmohan.com/'> Dr. Vishuu Mohan</a>, have won the ‘Best Demonstration’ 
    award at the 2025 UKRI AI & Robotics Research Awards held at the Royal Society London on March 12th. The award recognises the most impactful research demonstration celebrating creativity and innovation, 
    highlighting the ability to showcase technical achievements in real world applications with evidence of public, academic and industry interest.</p>
    <p>The team presented a fully autonomous dual-arm mobile robot for soft fruit harvesting and other repetitive farm tasks, like seedling transplanting and lettuce harvesting. 
      This technology could improve productivity, reduce labor costs, and enhance food security, changing the future of farming.</p>
    <p>The Essex team successfully field-tested its solutions at Wilkin &amp; Sons Ltd. and JEPCO’s
hydroponic lettuce farm, while the Versatile RobotX team is also developing low-cost, 3D-
printable robotic components to make adoption more accessible.</p>
    <p>This recognition underscores Essex’s contributions to AI-driven automation and aligns with
    ARISE’s mission to advance robotics solutions for sustainable agriculture.</p>
    <p>Read the full article <a href='https://www.essex.ac.uk/news/2025/03/13/essex-robotics-team-wins-national-award'>here</a>.</p>
                
                </div>
                
                
                </div>
               
                
              </section>
               
                    
          </div>


          
        </div>
      </div>
      <DarkFooter/>
    </div>
  )
}

export default Article12